<script setup>
import CIcon from '@/components/common/CIcon.vue';

const props = defineProps({
  theme: {
    type: String,
    default: 'dark',
    validator(theme) {
      return ['dark', 'white'].includes(theme);
    },
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <button
    type="button"
    class="ui-button-close"
    :class="{
      [`ui-button-close--theme--${props.theme}`]: props.theme,
    }"
    @click="emitClick"
  >
    <CIcon name="close" class="ui-button-close__icon" />
  </button>
</template>

<style scoped lang="scss">
.ui-button-close {
  $parent: &;

  width: em(40);
  height: em(40);
  background-color: transparent;
  border: none;
  transition: opacity $time-slow $ease-out;

  @include hover {
    opacity: 0.8;
  }

  &__icon {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }

  &--theme {
    &--dark {
      color: $color-grey-300;
    }

    &--white {
      color: $color-white;
    }
  }
}
</style>
